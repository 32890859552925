import { render, staticRenderFns } from "./AvatarUser.vue?vue&type=template&id=120af324&"
import script from "./AvatarUser.vue?vue&type=script&lang=js&"
export * from "./AvatarUser.vue?vue&type=script&lang=js&"
import style0 from "./AvatarUser.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VIcon,VImg})
